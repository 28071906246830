const degreesPerMinute = 360 / 60; // 6
const tickingMinuteHand = document.querySelectorAll(
  ".pf__ticking-clock__minute-hand"
);

if (tickingMinuteHand) {
  tickingMinuteHand.forEach(function (el) {
    let rotate = degreesPerMinute * -6;
    setInterval(function () {
      el.style.transform = "rotate(" + rotate + "deg)";
      rotate += degreesPerMinute;
    }, 1000);
  });
}

if (document.getElementsByClassName("pf__the-book-slide-1__gallery__splide").length) {
  var bookImagesSplide = new Splide(".splide.pf__the-book-slide-1__gallery__splide", {
    type: "slide",
    rewind: true,
    perPage: 1
  }).mount();
}
